import {type LoaderFunction, type MetaDescriptor, type MetaFunction} from '@remix-run/node';

const isEqual = (a: MetaDescriptor, b: MetaDescriptor) => {
  const _isEqual =
    ('name' in a && 'name' in b && a.name === b.name) ||
    ('property' in a && 'property' in b && a.property === b.property) ||
    ('title' in a && 'title' in b) ||
    ('tagName' in a && 'tagName' in b && a.tagName === 'link') || // TODO:
    ('script:ld+json' in a && 'script:ld+json' in b); // TODO:
  return _isEqual;
};

export const createMetaHandler = <
  Loader extends LoaderFunction | unknown = unknown,
  ParentsLoaders extends Record<string, LoaderFunction | unknown> = Record<string, unknown>,
>(
  leafMetaFn: MetaFunction<Loader, ParentsLoaders>,
): MetaFunction<Loader, ParentsLoaders> => {
  return (args) => {
    return [...args.matches, {meta: leafMetaFn(args)}].reduceRight((p, c) => {
      c.meta.forEach((parentMeta) => {
        if (p.findIndex((meta) => isEqual(meta, parentMeta)) === -1) {
          p.push(parentMeta);
        }
      });

      return p;
    }, [] as MetaDescriptor[]);
  };
};

//   return matches.reduceRight(
//     (p, c) => {
//       c.meta.forEach((parentMeta) => {
//         if (p.findIndex((meta) => isEqual(meta, parentMeta)) === -1) {
//           p.push(parentMeta);
//         }
//       });

//       return p;
//     },
//     [
//       {title: post.title},
//       {content: post.excerpt, name: 'description'},
//       {content: post.title, property: 'og:title'},
//       {content: post.excerpt, property: 'og:description'},
//       {content: post.title, name: 'twitter:title'},
//       {content: post.excerpt, name: 'twitter:description'},
//     ] as MetaDescriptor[],
//   );
// }
